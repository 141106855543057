/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryClient } from "@tanstack/react-query";
import { useCamera } from "components/Camera";
import { Header } from "components/Header";
import useGetLocker from "hooks/useGetLocker";
import useOpenDoor from "hooks/useOpenDoor";
import useUpdateReservation from "hooks/useUpdateReservation";
import { useCallback, useEffect, useRef } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

const DEPOSIT = "deposit";
const WITHDRAW = "withdraw";
const DEPOSIT_PATH = "/deposit-success";
const DEPOSIT_PHOTO = "deposit_photo";
const WITHDRAW_PHOTO = "withdrawal_photo";
const WITHDRAW_PATH = "/withdraw-success";
const MULTIPLE_WITHDRAW_PATH = "/withdraw-confirm";

export default function CodeProgress() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const reservation = location.state?.reservation;
  const action = location.state?.action;
  const queryClient = useQueryClient();
  const openDoor = useOpenDoor();
  const camera = useCamera();
  const lockerQuery = useGetLocker({ refetchOnMount: false });
  const updateTriggered = useRef(false);

  const handleNavigation = useCallback(
    (path: string) => {
      navigate(path, { state: { reservation, action } });
    },
    [navigate, reservation, action]
  );

  const onSuccess = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ["locker"] });
    
    const currentAction = action;
    const currentReservation = reservation;
    const currentHandleNavigation = handleNavigation;

    let path = currentAction === DEPOSIT ? DEPOSIT_PATH : WITHDRAW_PATH;
    
    if (currentAction === WITHDRAW && currentReservation.multiple_withdrawals_allowed) {
      path = MULTIPLE_WITHDRAW_PATH;
    } else if (currentReservation.door?.position) {
      await openDoor.trigger(currentReservation.door.position);
    }
    
    currentHandleNavigation(path);
  }, [action, reservation.door?.position, handleNavigation, queryClient]);

  const onError = useCallback((error: Error) => {
    console.error("Error updating reservation:", error);
    updateTriggered.current = false;
  }, []);

  const updateReservation = useUpdateReservation({ onSuccess, onError });

  useEffect(() => {
    if (!reservation?.id || !action || updateTriggered.current) return;

    const executeUpdate = async () => {
      updateTriggered.current = true;
      
      const screenshot = camera?.isEnabled ? camera.capture() : null;
      const variables = {
        id: reservation.id,
        [action]: true,
        [action === DEPOSIT ? DEPOSIT_PHOTO : WITHDRAW_PHOTO]: screenshot || undefined
      };

      if (action === WITHDRAW && reservation.multiple_withdrawals_allowed) {
        delete variables[action];
      }

      try {
        await updateReservation.mutateAsync(variables);
      } catch (error) {
        updateTriggered.current = false;
      }
    };

    if (!lockerQuery.isInitialLoading) {
      executeUpdate();
    }
  }, [reservation?.id, action, lockerQuery.isInitialLoading, camera?.isEnabled]);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <Header />
      <Container className="mt-5 p-3 w-75">
        <div className="mt-5 border-start border-3 rounded border-primary bg-surface text-on-surface text-center shadow px-5 py-4">
          <div className="display-6 fw-light mb-4">
            {t("codeProgress.loading")}
          </div>
          <Spinner animation="border" variant="primary" />
        </div>
      </Container>
    </div>
  );
}
