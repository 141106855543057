import * as Sentry from "@sentry/react";
import { useCallback, useRef } from "react";

export async function openDoor(position: string) {
  const xpos = position[0];
  const ypos = position.substring(1);
  const url = `http://127.0.0.1:5000/?xpos=${xpos}&ypos=${ypos}`;

  try {
    await fetch(url, { mode: "no-cors", cache: "no-store" });
    return true; // Assume success
  } catch (error) {
    throw new Error(
      `Failed to open door ${position}: ${error instanceof Error ? error.message : error}`
    );
  }
}

export default function useOpenDoor() {
  const lastTrigger = useRef<{ position: string; time: number } | null>(null);

  const trigger = useCallback(
    async (position: string, bypassDebounce = false) => {
      const now = Date.now();
      const last = lastTrigger.current;

      // Skip if same door was triggered within last 3 seconds (unless bypassing)
      if (
        !bypassDebounce &&
        last?.position === position &&
        now - last.time < 3000
      ) {
        return;
      }

      lastTrigger.current = { position, time: now };

      try {
        await openDoor(position);
      } catch (error) {
        console.error("Door controller error:", error);
        Sentry.withScope((scope) => {
          scope.setTag("door_position", position);
          scope.setLevel("error");
          Sentry.captureException(error);
        });
      }
    },
    []
  );

  return { trigger };
}
